<template>
    <div class="monitor-index-c">
        <div class="title">{{ title }}</div>
        <div class="home-tabber">
			<div class="tab-box">
				<div class="cell" @click="showDate('day')" :class="{'active': active == 'day'}">{{langset.day}}</div>
				<div class="cell" @click="showDate('month')" :class="{'active': active == 'month'}">{{langset.month}}</div>
				<div class="cell" @click="showDate('year')" :class="{'active': active == 'year'}">{{langset.year}}</div>
				<div class="cell" @click="showDate('more')" :class="{'active': active == 'more'}">{{langset.more}}</div>
			</div>
            <div class="monitor-date">
                <p v-if="active == 'more'">{{ moreDateDes }}</p>
            </div>
            <div class="monitor-date" v-if="show">
                <van-calendar v-model="show" :close-on-click-overlay="false" :min-date="minDate" type="range" color="#00ADB5" @confirm="onConfirm" />
            </div>
            <!-- 检测图表插槽 -->
			<div class="unit">
                <p>{{langset.unit}}：{{unit}}</p>
                <div>
                    <p>
                        <span></span>
                        {{langset.charge}}
                    </p>
                    <p>
                        <span></span>
                        {{langset.discharge}}
                    </p>
                </div>
            </div>
			
        </div>
		<div class="plantsprofits">
		    <div class="charts" style="width: 100%;height: 155px;margin-top: 10px" ref="charts"></div>
		</div>
    </div>
</template>
<script>
	//引入api
	import { dataInterface } from '@/api/dataInterfaceApi';
	//引入mixin
	import mixin from '@/utils/mixin';
	//引入首页中英文文件
	import langObj from '@/utils/site';
    export default {
		mixins: [mixin],
        props: {
            title: String
        },
        data() {
            return {
				dataList: [],
                active: 'day',
				unit: '',
				minDate: new Date(2010, 0, 1),
                value: '',
				moreDateDes: '',
                moreDate: [],
                show: false,
				showconfirm: false,
				option: {}
            }
        },
		watch: {},
        methods: {
            showDate(type) {
				if(type == "more") {
					this.show = true
					return 
				}
				this.active = type
				this.initChart()
            },
            rangeForm(date) {
                return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
            },
            onConfirm(date) {
				const start = this.rangeForm(date[0])
				const end = this.rangeForm(date[1])
				this.moreDate[0] = start
				this.moreDate[1] = end
				this.active = 'more'
				this.show = false
                this.moreDateDes = `${start} 至 ${end}`
				this.initChart()
            },
			initChart() {
				let that = this
			    let myEcharts = this.$echarts.init(this.$refs.charts)
				let date_state = this.active
				if(date_state == 'more'){
					date_state = ""
				}
				dataInterface
					.dataFaceApi({
						"enCode": "stored-overview-chg-dischg-elect-tendency",// 接口code
						"@station_id": localStorage.getItem("stationPStorageId"), //  站点id 例如621025075751045445
						"@date_state": date_state, //   例如day，month,year
						"@start_date": this.moreDate[0] ? this.moreDate[0] : '2024-11-8', //  日期的开始范围  自定义
						"@end_date": this.moreDate[1]  ? this.moreDate[1] : '2024-11-9'// 日期的结束范围
					})
					.then((res) => {
						if (res[0]) {
							this.unit = res[0].chg_elect_unit
						}
						this.option = {
					yAxis: {
						type: 'value',
						axisLabel: {
							formatter: function(value) {
								// 确保刻度值为正数
								return Math.abs(value);
							}
						}
					},
					grid: {
						left: '0%',
						right: '0%',
						top: '10%',
						bottom: '0%',
						containLabel: true
					},
					xAxis: {
						type: 'category',
						data: []
					},
					tooltip: {
                        trigger: 'axis',
                        formatter: function(arg) {
                            var div = `<div 
                            style="width: 137px; background: rgba( 255, 255, 255, .5);">
                                <p 
                                style="font-family: PingFang SC, PingFang SC;
                                font-weight: bold;
                                font-size: 14px;
                                color: rgba(0,0,0,0.9);
                                line-height: 22px; 
                                margin-bottom: 4px;">${arg[0].axisValue}</p>
                                <div 
                                style="display: flex; 
                                align-items: center; 
                                line-height: 20px;
                                margin-bottom: 4px;">
                                <div 
                                    style="width: 10px;
                                    height: 10px;
                                    background: #FFFFFF;
                                    box-shadow: 0px 11px 12px 0px rgba(41,198,200,0.25);
                                    border-radius: 11px 11px 11px 11px;
                                    border: 3px solid #00ADB5;
                                    margin-right: 4px;">
                                </div>
                                    ${that.langset.charge}
                                <p style="flex: 1; text-align: right;">
                                    <span style="font-weight: bold">${arg[0].value}</span>${that.unit}
                                </p>
                                </div>
                                <div 
                                style="display: flex; 
                                align-items: center; 
                                line-height: 20px;">
                                <div 
                                    style="width: 10px;
                                    height: 10px;
                                    background: #FFFFFF;
                                    box-shadow: 0px 11px 12px 0px rgba(41,198,200,0.25);
                                    border-radius: 11px 11px 11px 11px;
                                    border: 3px solid #FF9A2E;
                                    margin-right: 4px;">
                                </div>
                                    ${that.langset.discharge}
                                <p style="flex: 1; text-align: right;">
                                    <span style="font-weight: bold">${Math.abs(arg[1].value)}</span>${that.unit}
                                </p>
                                </div>
                            </div>`
                        return div
                        }
                    },
					series: [{
						data: [],
						type: 'bar',
						stack: 'total',
						barWidth: '20%',
						itemStyle: {
							borderRadius: [22, 22, 22, 22], // 这里可以调整圆角的大小
							color: function (params) {
								// 如果当前数值大于0，则为一个颜色；小于0，则为另一个颜色
								return params.value > 0 ? '#00ADB5' : '#FF9A2E';
							}
						}
					},
					{
						data: [],
						stack: 'total',
						type: 'bar',
						barWidth: '20%',
						itemStyle: {
							borderRadius: [22, 22, 22, 22], // 这里可以调整圆角的大小
							color: function (params) {
								// 如果当前数值大于0，则为一个颜色；小于0，则为另一个颜色
								return params.value > 0 ? '#00ADB5' : '#FF9A2E';
							}
						}
					}]
				}
						this.dataList = res ? res : []
						this.option.xAxis.data = []
						this.option.series[0].data = []
						this.option.series[1].data = []
						this.dataList.forEach((item) => {
							this.option.xAxis.data.push(item.cur_date)
							this.option.series[0].data.push(item.chg_elect)
							this.option.series[1].data.push(item.dischg_elect)
						})
						myEcharts.setOption(this.option)
					})
					.catch((err) => {
						console.log(err);
					});
			}
        },
		mounted() {
			// 设置语言
			this.langset = langObj[this.lang]
		    this.initChart()
		}
    }
</script>
<style lang="scss" scpoed>
    .monitor-index-c{
        background: #FFFFFF;
        border-radius: 16px 16px 16px 16px;
        padding: 12px;
        margin: 0 12px;
        margin-bottom: 12px;
        .title {
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            padding: 4px 0 16px 0;
        }
        > .home-tabber {
            padding: 2px 0;
            background: #fff;
            .van-tabs__wrap {
                height: 30px;
                box-sizing: border-box;
            }
            .van-tabs__line {
                display: none;
            }
            .van-tabs__nav {
                height: 30px;
                padding-bottom: 0px;
                padding: 0 2px;
                border-radius: 20px 20px 20px 20px;
                background: #F7F8FA;
            }
            .van-tab {
                height: 30px !important;
                line-height: 30px;
            }
            .van-tab--active {
                width: 119px;
                height: 36px;
                background: #00ADB5;
                border-radius: 20px;
                .van-tab__text {
                    font-weight: bold;
                    font-size: 14px;
                    color: #FFFFFF;
                    line-height: 22px;
                }
            }
			.unit {
                display: flex;
                align-items: center;
                color: rgba(0,0,0,0.45);
                > p {
                    flex: 1;
                }
                div {
                    display: flex;
                    > p {
                        margin-left: 20px;
                        display: flex;
                        align-items: center;
                        span {
                            width: 15px;
                            height: 3px;
                        }
                    }
                    > p:first-child {
                        span {
                            background: #00ADB5;
                        }
                    }
                    > p:last-child {
                        span {
                            background: #FF9A2E;
                        }
                    }
                }
            }
			.tab-box{
				width: 100%;
				height: 40px;
				line-height: 40px;
				text-align: center;
				border-radius: 20px;
				background-color: #F7F8FA;
				color: rgba(0,0,0,0.9);
				display: flex;
				.cell{
					flex: 1;
				}
				.active{
					border-radius: 20px;
					color: #FFFFFF;
					background-color: #00ADB5;
				}
			}
        }
        .monitor-date {
            height: 36px;
            color: #000000;
            display: flex;
            align-items: center;
            justify-content: center;
            > p {
                width: 100%;
                height: 100%;
                text-align: center;
                line-height: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #000000;
                .van-icon {
                    margin-left: 10px;
                }
            }
        }
    }
</style>