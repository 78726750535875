<template>
    <div class="electricityLevel">
        <div>
            <div class="top">
                <img src="@/assets/images/dianIcon1.png" alt="">
                <span>{{langset.Dailychargingcapacity}}</span>
            </div>
            <div class="bottom">
                <p>{{stationInfo.today_chg_elect || "-"}}</p>
                <span>{{stationInfo.today_chg_elect_unit || "-"}}</span>
            </div>
        </div>
        <div>
            <div class="top">
                <img src="@/assets/images/dianIcon2.png" alt="">
                <span>{{langset.Dailydischargecapacity}}</span>
            </div>
            <div class="bottom">
                <p>{{stationInfo.today_dischg_elect || "-"}}</p>
                <span>{{stationInfo.total_dischg_elect_unit || "-"}}</span>
            </div>
        </div>
    </div>
</template>
<script>
	//引入api
	import { dataInterface } from '@/api/dataInterfaceApi'
	//引入mixin
	import mixin from '@/utils/mixin'
	//引入首页中英文文件
	import langObj from '@/utils/site'
	export default {
		mixins: [mixin],
		data() {
			return {
				stationInfo:{}
			}
		},
		methods: {
			// 获取日充日放数据
			getData() {
				dataInterface
					.dataFaceApi({
						"enCode": "stored-overview-central-information-overview", // 接口code
						"@station_id": localStorage.getItem("stationPStorageId") //  站点id 例如621025075751045445
					})
					.then((res) => {
						this.stationInfo = res ? res[0] : {}
					})
					.catch((err) => {
						console.log(err);
					});
			}
		},
		mounted() {
			// 设置语言
			this.langset = langObj[this.lang]
			this.getData()
		}
	}
</script>
<style lang="scss">
    .electricityLevel {
        display: flex;
        justify-content: space-between;
        padding: 0 12px;
        margin-top: 12px;
        > div {
            width: 48%;
            background: #FFFFFF;
            border-radius: 16px 16px 16px 16px;
            padding: 12px;
            .top {
                display: flex;
                align-items: center;
                color: rgba(0,0,0,0.7);
				line-height: 16px;
                margin-bottom: 4px;
            }
            img {
                width: 20px;
                height: 20px;
                margin-right: 4px;
            }
            .bottom {
                display: flex;
                align-items: center;
                color: rgba(0,0,0,0.7);
                p {
                    color: rgba(0,0,0,0.9);
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 22px;
                }
            }
        }
    }
</style>