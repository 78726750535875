let lang = {
	EN: {
		Lightstorage: "LightStorage",
		Energystorage: "EnergyStorage",
		photovoltaic: "Photovoltaic",
		Powerstationrevenue: "Power station revenue",
		Energystoragedischargemonitoring: "Energy storage discharge monitoring",
		Photovoltaicpowergenerationmonitoring: "Photovoltaic power generation monitoring",
		Filter: "Filter",
		cn: "cn",
		en: "en",
		language: "language",
		currency: "currency",
		RMB: "RMB",
		dollar: "dollar",
		peso: "peso",
		Taiwancurrency: "Taiwan currency",
		euro: "euro",
		HongKongdollars: "Hong Kong dollars",
		JapaneseYen:"Japanese Yen",
		confirm: "confirm",
		index: "index",
		Charginganddischargingtrends: "Charging and discharging trends",
		Electricitypricetrend: "Electricity price trend",
		Realtimepowermonitoring: "Real time power monitoring"
	},
	CN:{
		Lightstorage: "光储概览",
		Energystorage: "储能概览",
		photovoltaic: "光伏概览",
		Powerstationrevenue: "电站收益",
		Energystoragedischargemonitoring: "储能放电检测",
		Photovoltaicpowergenerationmonitoring: "光伏发电监测",
		Filter: "筛选",
		cn: "中文",
		en: "英文",
		language: "语言",
		currency: "货币",
		RMB: "人民币",
		dollar: "美金",
		peso: "比索",
		Taiwancurrency: "台币",
		euro: "欧元",
		HongKongdollars: "港币",
		JapaneseYen:"日元",
		confirm: "确定",
		index: "首页",
		Charginganddischargingtrends: "充放电趋势",
		Electricitypricetrend: "电价趋势",
		Realtimepowermonitoring: "实时功率监测"
	}
}

export default lang;