<template>
    <div class="weather">
        <div>
            <div class="icon">
                <img src="@/assets/images/weather.png" alt="">
            </div>
            <div class="information">
                <p><span>{{stationInfo.irradiance || "-"}}</span>w/㎡</p>
                <span>{{langset.irradiation}}</span>
            </div>
            <div class="information">
                <p><span>{{stationInfo.humidness || "-"}}%</span></p>
                <span>{{langset.humidity}}</span>
            </div>
            <div class="information">
                <p><span>{{stationInfo.temperature || "-"}}℃</span></p>
                <span>{{langset.temperature}}</span>
            </div>
            <div class="information">
                <p><span>{{stationInfo.wind_speed || "-"}}</span>m/s</p>
                <span>{{langset.windspeed}}</span>
            </div>
        </div>
    </div>
</template>
<script>
	//引入api
	import { dataInterface } from '@/api/dataInterfaceApi';
	//引入mixin
	import mixin from '@/utils/mixin';
	//引入首页中英文文件
	import langObj from '@/utils/site';
	export default {
		name: 'homeTab',
		mixins: [mixin],
		data() {
			return {
				stationInfo:{}
			}
		},
		methods: {
			// 跳转页面
			onClickRight(page) {
				this.$router.push({
					name: page
				})
			},
			// 获取头部数据
			getData() {
				dataInterface
					.dataFaceApi({
						"enCode": "solar-overview-meteorological-monitoring-real", // 接口code
						"@station_id": localStorage.getItem("stationPvId") //  城市名称
					})
					.then((res) => {
						console.log(res, ',,,,,,,,,,,,,,,,,,,,,,')
						this.stationInfo = res ? res[0] : {}
					})
					.catch((err) => {
						console.log(err);
					});
			}
		},
		mounted() {
			// 设置语言
			this.langset = langObj[this.lang]
			this.getData()
		}
	}
</script>
<style lang="scss" scoped>
    .weather {
        padding: 0 12px;
        margin-top: 12px;
        > div {
            padding: 12px;
            height: 72px;
            background: #fff;
            border-radius: 16px 16px 16px 16px;
            display: flex;
            .icon {
                width: 48px;
                height: 48px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .information {
                flex: 1;
                border-left: 1px solid #F7F8FA;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: rgba(0,0,0,0.7);
                p {
                    span {
                        color: rgba(0,0,0,0.9);
                        font-weight: 400;
                    }
                }
            }
            .information:first-child {
                border-left: 0;
            }
        }
    }
</style>