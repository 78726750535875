<template>
	<div class="siteDetails">
		<back></back>
		<!-- <van-nav-bar :title="langset.siteDetailsPage" >
			<template #left>
				<van-icon name="arrow-left" color="#00ADB5" size="18"  @click="goback"/>
			</template>
		</van-nav-bar> -->
		<div class="detailsImg">
			<van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
				<van-swipe-item v-for="(item, index) in project_real_img" :key="index">
					<img :src="base + item.url" alt="">
				</van-swipe-item>
			</van-swipe>
		</div>
		<div class="details-info">
			<van-cell-group inset>
				<van-cell :title="langset.Belongingcompany" :value="stationInfo.enterprise_name" />
				<van-cell :title="langset.Powerstationtype" :value="stationInfo.type_desc" />
				<van-cell :title="langset.Operationmode" :value="stationInfo.operation_mode_desc" />
				<van-cell :title="langset.Operationstatus" :value="stationInfo.operation_status" />
			</van-cell-group>
			<van-cell-group inset>
				<van-cell :title="langset.region" :value="stationInfo.area" />
				<van-cell :title="langset.geographicalposition" :value="stationInfo.location" />
				<van-cell :title="langset.longitude" :value="stationInfo.longitude" />
				<van-cell :title="langset.latitude" :value="stationInfo.latitude" />
			</van-cell-group>
			<van-cell-group inset>
				<van-cell :title="langset.Photovoltaicinstalledcapacity" :value="stationInfo.photo_voltaic_install_capacity" />
				<van-cell :title="langset.Operationdate" :value="stationInfo.operation_date" />
				<van-cell :title="langset.Electricityclassification" :value="stationInfo.use_electricity_type_id" />
				<van-cell :title="langset.Voltagesystem" :value="stationInfo.voltage_regulation" />
				<van-cell :title="langset.Voltagelevel" :value="stationInfo.voltage_level" />
			</van-cell-group>
		</div>
	</div>
</template>
<script>
	//引入api
	import {dataInterface} from '@/api/dataInterfaceApi';
	import base from '@/utils/base';
	//引入mixin
	import mixin from '@/utils/mixin';
	//引入首页中英文文件
	import langObj from '@/utils/site';
	import back from '@/components/back.vue';
	export default {
		name: 'homeTab',
		mixins: [mixin],
		data() {
			return {
				stationInfo: {},
				base: base,
				project_real_img: []
			}
		},
		components:{
			back
		},
		methods: {
			//返回
			goback() {
				this.$router.go(-1)
			},
			// 获取站点详情
			getData() {
				// 获取站点详情
				dataInterface
					.dataFaceApi({
						"enCode": "solar-overview-station-info", // 接口code
						"@station_id": localStorage.getItem("stationPvId") //  站点id 例如621025075751045445
					})
					.then((res) => {
						this.stationInfo = res ? res[0] : {}
					})
					.catch((err) => {
						console.log(err);
					});
				// 获取轮播图
				dataInterface
					.dataFaceApi({
						"enCode": "solar-overview-project-real", // 接口code
						"@station_id": localStorage.getItem("stationPvId") //  站点id 例如621025075751045445
					})
					.then((res) => {
						if (res) {
							try {
								this.project_real_img = JSON.parse(res[0].project_real_img)
								console.log(this.project_real_img)
							} catch (e) {
								//TODO handle the exception
							}
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}
		},
		mounted() {
			// 设置语言
			this.langset = langObj[this.lang]
			this.getData()
		}
	}
</script>

<style lang="scss">
	.siteDetails {
		width: 100%;
		height: 100%;
		overflow: auto;

		.detailsImg {
			width: 100%;
			height: 238px;
			background: #D9D9D9;
			.my-swipe{
				width: 100%;
				height: 230px;
			}
			img {
				width: 100%;
				height: 100%;
				margin: 0;
				padding: 0;
			}
		}

		.details-info {
			.van-cell-group {
				margin-top: 12px;
				border-width: 0px;
			}

			.van-cell {
				height: 48px;
				padding: 0 12px;
				line-height: 48px;
				font-size: 14px !important;
			}

			.van-cell__title {
				color: rgba(0, 0, 0, 0.7);
			}

			.van-cell__value {
				color: rgba(0, 0, 0, 0.9);
			}

			.van-cell::after {
				border-bottom: 0px;
			}
		}
	}
</style>