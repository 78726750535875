<template>
    <div class="contribute">
        <div class="title">{{langset.Socialcontribution}}</div>
        <div class="contribute-list">
            <div>
                <div class="num"><span>{{stationInfo.saving_standard_coal || "-"}}</span>{{stationInfo.saving_standard_coal_unit || "-"}}</div>
                <p>{{langset.Savestandardcoal}}</p>
                <div class="img">
                    <img src="@/assets/images/jy.png" alt="">
                </div>
            </div>
            <div>
                <div class="num"><span>{{stationInfo.co2 || "-"}}</span>{{stationInfo.co2_unit || "-"}}</div>
                <p>{{langset.CO2carbonreduction}}</p>
                <div class="img">
                    <img src="@/assets/images/tj.png" alt="">
                </div>
            </div>
            <div>
                <div class="num"><span>{{stationInfo.equivalent_planting || "-"}}</span>{{stationInfo.equivalent_planting_unit || "-"}}</div>
                <p>{{langset.Equivalenttreeplantingamount}}</p>
                <div class="img">
                    <img src="@/assets/images/zs.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
	//引入api
	import { dataInterface } from '@/api/dataInterfaceApi';
	//引入mixin
	import mixin from '@/utils/mixin';
	//引入首页中英文文件
	import langObj from '@/utils/site';
    export default {
		mixins: [mixin],
        data() {
            return {
				stationInfo: {}
			}
        },
        methods: {
			// 获取数据
			getData() {
				dataInterface
					.dataFaceApi({
						"enCode": "solar-stored-overview-energy-contribution", // 接口code
						"@station_id": localStorage.getItem('stationId') //  站点id 例如621025075751045445
					})
					.then((res) => {
						this.stationInfo = res ? res[0] : {}
					})
					.catch((err) => {
						console.log(err);
					});
			}
        },
        mounted() {
			// 设置语言
			this.langset = langObj[this.lang]
			this.getData()
        },
    }
</script>
<style lang="scss">
    .contribute{
        height: 186px;
        background: #FFFFFF;
        border-radius: 16px 16px 16px 16px;
        padding: 12px;
        margin: 0 12px;
        padding-top: 0;
        .title {
            font-weight: 800;
            font-size: 16px;
            line-height: 24px;
            padding: 16px 0;
        }
        .contribute-list {
            display: flex;
            > div {
                flex: 1;
				flex-shrink: 0;
                height: 118px;
                border-radius: 8px 8px 8px 8px;
                padding:  12px 0;
                display: flex;
                flex-direction: column;
                align-items: center;
				overflow: hidden;
                color: rgba(0,0,0,0.7);
				text-align: center;
                span {
                    font-size: 20px;
                    line-height: 28px;
                    color: rgba(0,0,0,0.9);
                }
            }
            > div:first-child {
                background: linear-gradient( 180deg, rgba(205,206,252,0.5) 0%, rgba(255,255,255,0) 100%);
            }
            > div:nth-child(2) {
                margin: 0 12px;
                background: linear-gradient( 180deg, rgba(253,235,165,0.5) 0%, rgba(255,255,255,0) 100%);
            }
            > div:last-child {
                background: linear-gradient( 180deg, rgba(191,255,174,0.5) 0%, rgba(255,255,255,0) 100%);
            }
        }
    }
</style>