<template>
	<div class="back-main-box" @click="back">
		<img class="img" src="@/assets/images/backimg.png" alt="">
	</div>
</template>

<script>
	export default{
		methods:{
			back(){
				this.$router.go(-1)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.back-main-box{
		position: fixed;
		left: 0;
		top: 20%;
		width: 58px;
		height: 40px;
		background: #FFFFFF;
		box-shadow: 0px 4px 19px 0px rgba(0,0,0,0.25);
		border-radius: 0px 24px 24px 0px;
		z-index: 100;
		.img{
			width: 25px;
			margin: 8px 15px;
		}
	}
</style>