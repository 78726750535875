<template>
    <div class="pv-total-earnings-box">
        <div>
            <div class="ratio" ref="charts1"></div>
            <div>
                <p>ROI：{{langset.totalroi}}</p>
                <span>{{langset.Totalrevenue}}/{{langset.Totalinvestmentamount}}*100%</span>
            </div>
        </div>
        <div class="information">
            <div class="total">
                <div><img src="@/assets/images/sufficient.png" alt=""></div>
                <div>
                    <p>{{langset.Totalinstalledcapacity}}</p>
                    <div>
                        <p>{{electObj.photo_voltaic_install_capacity || "-"}}</p>
                        <span>{{electObj.photo_voltaic_install_capacity_unit || "-"}}</span>
                    </div>
                </div>
            </div>
            <div class="qiuCharts" ref="qiuCharts"></div>  
        </div>
    </div>
</template>
<script>
	//引入api
	import { dataInterface } from '@/api/dataInterfaceApi';
	//引入mixin
	import mixin from '@/utils/mixin';
	//引入首页中英文文件
	import langObj from '@/utils/site';
    export default {
        name: 'totalEarnings',
		mixins: [mixin],
        data() {
            return {
				currentRate: 0,
				electObj: {},
				name: '实时功率',
                option: {
                    series: [{
                        type: 'gauge',
                        radius: '93%',
                        progress: {
                            show: true,
                            width: 8,
                            backgroundColor:'#4DD3D0'
                        },
                        itemStyle: {
                            color: '#4DD3D0'
                        },
                        pointer: {
                            length: '75%',
                            width: 2,
                            offsetCenter: [0, '5%']
                        },
                        axisLine: {
                            lineStyle: {
                                width: 8
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            length: 4,
                            lineStyle: {
                                width: 1,
                                color: '#4DD3D0'
                            }
                        },
                        axisLabel: {
                            show: false
                        },
                        anchor: {
                            show: true,
                            showAbove: true,
                            size: 8,
                            itemStyle: {
                                borderWidth: 3,
                                borderColor: '#4DD3D0'
                            }
                        },
                        title: {
                            show: false
                        },
                        detail: {
                            valueAnimation: true,
                            fontSize: 18,
                            offsetCenter: [0, '70%'],
							z: 10,
                            formatter: function (value) {
                                return value.toFixed(2) + '%'
                            }
                        },
						center : ['50%', '52%'],
                        data: [{
                            value: 0,
							detail: {
								offsetCenter: ['0%', '93%'],
								// 通过设置 z 值，确保数字显示在最上层
								z: 2000
							}
                        }]
                    }]
                },
                qiuOption: {
                    grid: {
                        left: '0%',
                        right: '0%',
                        top: '0%',
                        bottom: '0%',
                        containLabel: true
                    },
                    
                    series:[{
                        type: 'liquidFill',
                        radius: '100%',
                        center: ['50%','50%'],
                        data: [0.5, {
                            value: 0.5,
                            derection: 'left'
                        }],
                        outline: {
                            borderDistance: 0,
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: '#AFF0EB'
                            }
                        },
                        backgroundColor: {
                            borderWidth: 1,
                            color: '#ffffff'
                        },
                        itemStyle: {
                            shadowBlur: 0
                        },
                        label: {
                            normal: {
                                formatter: '{a|0}{hr|kw}\n{hr|'+name+'}',
                                rich: {
                                    a: {
                                        color: 'rgba(0,0,0,0.9)',
                                        fontSize: 20,
                                        lineHeight: 22,
                                        fontWeight: '800'
                                    },
                                    hr: {
                                        color: 'rgba(0,0,0,0.9)',
                                        fontSize: 14,
                                        lineHeight: 22,
                                        fontWeight: '400'
                                    }
                                },
                            }
                        },
                        backgroundStyle: {
                            color: '#fff'
                        },
                        color: ['#AFF0EB', '#4DD3D0']
                    }]
                }
            }
        },
        methods: {
            initChart() {
                let myEcharts = this.$echarts.init(this.$refs.charts1)
				
				// 获取roi数据
				dataInterface
					.dataFaceApi({
						"enCode": "solar-overview-ROI", // 接口code
						"@station_id": localStorage.getItem("stationPvId") //  站点id 例如621025075751045445
					})
					.then((res) => {
							try{
								this.currentRate = res ? res[0].roi : 0
								this.currentRate = this.currentRate * 1
								this.option.series[0].data[0].value = this.currentRate
								myEcharts.setOption(this.option)
							}catch(e){
								myEcharts.setOption(this.option)
								//TODO handle the exception
							}
						
					})
					.catch((err) => {
						console.log(err);
					});
					
				// 获取电量数据
				dataInterface
					.dataFaceApi({
						"enCode": "solar-overview-station-info", // 接口code
						"@station_id": localStorage.getItem("stationPvId") //  站点id 例如621025075751045445
					})
					.then((res) => {
						try{
							this.electObj = res ? res[0]: {}
						}catch(e){
							//TODO handle the exception
						}
					})
					.catch((err) => {
						console.log(err);
					});
                
            },
            initQiuCharts() {
                let qiuEcharts = this.$echarts.init(this.$refs.qiuCharts)
                dataInterface
                	.dataFaceApi({
                		"enCode": "solar-overview-real-time-generating-capacity", // 接口code
                		"@station_id": localStorage.getItem("stationPvId") //  站点id 例如621025075751045445
                	})
                	.then((res) => {
                		if(res[0]){
                			this.qiuOption.series[0].label.normal.formatter = '{a|'+res[0].solar_power+'}{hr|'+res[0].solar_power_unit+'}\n{hr|'+this.name+'}'
                		} else {
							this.qiuOption.series[0].label.normal.formatter = '{a|0}{hr|kw}\n{hr|'+this.name+'}'
						}
                		
                		qiuEcharts.setOption(this.qiuOption)
                	})
                	.catch((err) => {
                		console.log(err);
                	});
            }
        },
        mounted() {
			// 设置语言
			this.langset = langObj[this.lang]
			if(localStorage.getItem("lang") == 'EN'){
				this.name = 'Realtimepower'
			} else {
				this.name = '实时功率'
			}
            this.initChart()
            this.initQiuCharts()
        }
    }   
</script>
<style lang="scss" scoped>
    .pv-total-earnings-box {
        display: flex;
        margin-top: 12px;
        padding: 0 12px;
		justify-content: space-between;
        > div {
            width: 49%;
            border-radius: 16px 16px 16px 16px;
        }
        > div:first-child {
            padding: 12px;
            background: #FFFFFF;
            margin-right: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 14px;
            .ratio {
                width: 100%;
                flex: 1;
            }
            > div {
                text-align: center;
                > span {
                    color: rgba(0,0,0,0.45);
                }
            }
        }
        .information {
            .total {
                padding: 12px;
                background: #FFFFFF;
                border-radius: 16px 16px 16px 16px;
                display: flex;
                > div:first-child {
                    width: 48px;
                    height: 48px;
                    border-radius: 8px;
                    margin-right: 8px;
                }
                > div:last-child {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    > div {
                        display: flex;
                        color: rgba(0,0,0,0.9);
                        p {
                            font-family: TCloud Number, TCloud Number;
                            font-size: 16px;
                            margin-right: 4px;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
        .qiuCharts {
            position: relative;
            margin-top: 8px;
            width: 100%;
            height: 122px;
            padding: 12px;
			border-radius: 13px;
            background: #FFFFFF;
        }
    }
</style>