let baseURL = "https://cceapi.m9ch.com"
// let baseURL = "https://sems.cce-energy.com"
// let baseURL = ''
// module.exports = {
// 	baseURL: process.env.VUE_APP_BASE_API
// }

// let baseURL = "https://sems-uat.cce-energy.com:8443"

// let baseURL = process.env.VUE_APP_BASE_API
// console.log(process, '99999999999999999')
export default baseURL;