import axios from 'axios';

//api地址

import base from '@/utils/base';
const baseURL =  base + "/api/";
let Authorization = ''
export default class BaseApi {
  constructor() {
    this.axios = axios.create({
      baseURL: baseURL,
      timeout: 1000 * 60,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
		'Authorization': ""
      },
    });
    // 增加请求拦截器
    this.axios.interceptors.request.use(
      config => {
		  config.headers.Authorization = 'bearer ' + localStorage.getItem("Authorization")
        return config;
      },
      error => {
        console.log(error)
        return;
      },
    );
    // 增加返回拦截器
    this.axios.interceptors.response.use(
      (resp) => {
        if (resp.status == 200 || resp.status==304) { 
          if(resp.data.code * 1!== 200) {
            return false;
          } else {
            return resp.data.data;
          }
        } else {
          return false;
        }
      },
      error => {
        console.log(error)
      },
    );
  }

  async post(url, data) {
    return await this.axios.post(url, data);
  }

  async get(url, params) {
    return await this.axios.get(url, {params});
  }

  axios;
}