<template>
    <div class="total-earnings-box">
        <div>
            <div class="ratio" ref="charts"></div>
            <div>
                <p>ROI：{{langset.totalroi}}</p>
                <span>{{langset.Totalrevenue}}/{{langset.Totalinvestmentamount}}*100%</span>
            </div>
        </div>
        <div>
            <div>
                <div><img src="@/assets/images/sufficient.png" alt=""></div>
                <div>
                    <p>{{langset.Totalchargingcapacity}}</p>
                    <div class="unit-number">
                        <p>{{electObj.total_chg_elect || "-"}}</p>
                        <span>{{electObj.total_chg_elect_unit || "-"}}</span>
                    </div>
                </div>
            </div>
            <div>
                <div><img src="@/assets/images/put.png" alt=""></div>
                <div>
                    <p>{{langset.Totaldischargecapacity}}</p>
                    <div class="unit-number">
                        <p>{{electObj.total_dischg_elect || "-"}}</p>
                        <span>{{electObj.total_dischg_elect_unit || "-"}}</span>
                    </div>
                </div>
            </div>
            <div>
                <div><img src="@/assets/images/deliver.png" alt=""></div>
                <div>
                    <p>{{langset.Totalpowergeneration}}</p>
                    <div class="unit-number">
                        <p>{{electObj.total_produce_elect || "-"}}</p>
                        <span>{{electObj.total_produce_elect_unit || "-"}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
	//引入api
	import { dataInterface } from '@/api/dataInterfaceApi';
	//引入mixin
	import mixin from '@/utils/mixin';
	//引入首页中英文文件
	import langObj from '@/utils/site';
    export default {
        name: 'totalEarnings',
		mixins: [mixin],
        data() {
            return {
				currentRate: 0,
				electObj: {},
                option: {
                    series: [{
                        type: 'gauge',
                        radius: '93%',
                        progress: {
                            show: true,
                            width: 8,
                            backgroundColor:'#4DD3D0'
                        },
                        itemStyle: {
                            color: '#4DD3D0'
                        },
                        pointer: {
                            length: '75%',
                            width: 2,
                            offsetCenter: [0, '5%']
                        },
                        axisLine: {
                            lineStyle: {
                                width: 8
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            length: 4,
                            lineStyle: {
                                width: 1,
                                color: '#4DD3D0'
                            }
                        },
                        axisLabel: {
                            show: false
                        },
                        anchor: {
                            show: true,
                            showAbove: true,
                            size: 8,
                            itemStyle: {
                                borderWidth: 3,
                                borderColor: '#4DD3D0'
                            }
                        },
                        title: {
                            show: false
                        },
                        detail: {
                            valueAnimation: true,
                            fontSize: 18,
                            offsetCenter: [0, '70%'],
							z: 10,
                            formatter: function (value) {
                                return value.toFixed(2) + '%'
                            }
                        },
						center : ['50%', '52%'],
                        data: [{
                            value: 0,
							detail: {
								offsetCenter: ['0%', '93%'],
								// 通过设置 z 值，确保数字显示在最上层
								z: 2000
							}
                        }]
                    }]
                }
            }
        },
        methods: {
            initChart() {
                let myEcharts = this.$echarts.init(this.$refs.charts)
				// 获取roi数据
				dataInterface
					.dataFaceApi({
						"enCode": "solar-stored-overview-ROI", // 接口code
						"@station_id": localStorage.getItem('stationId') //  站点id 例如621025075751045445
					})
					.then((res) => {
						try{
							this.currentRate = res ? res[0].roi : 0
							this.currentRate = this.currentRate * 1
							this.option.series[0].data[0].value = this.currentRate
							myEcharts.setOption(this.option)
						}catch(e){
							myEcharts.setOption(this.option)
							//TODO handle the exception
						}
					})
					.catch((err) => {
						console.log(err);
					});
					
				// 获取电量数据
				dataInterface
					.dataFaceApi({
						"enCode": "solar-stored-overview-upper-right-screen-info", // 接口code
						"@station_id": localStorage.getItem('stationId') //  站点id 例如621025075751045445
					})
					.then((res) => {
						try{
							this.electObj = res ? res[0]: {}
						}catch(e){
							//TODO handle the exception
						}
					})
					.catch((err) => {
						console.log(err);
					});
                
            }
        },
        mounted() {
			// 设置语言
			this.langset = langObj[this.lang]
            this.initChart()
        }
    }   
</script>
<style lang="scss" scoped>
    .total-earnings-box {
        display: flex;
        margin-top: 12px;
        padding: 0 12px;
		justify-content: space-between;
        > div {
            width: 49%;
            background: #FFFFFF;
            border-radius: 16px 16px 16px 16px;
            padding: 12px;
        }
        > div:first-child {
            margin-right: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 14px;
            .ratio {
                width: 100%;
                flex: 1;
            }
            > div {
                text-align: center;
                > span {
                    color: rgba(0,0,0,0.45);
                }
            }
        }
        > div:last-child {
            > div {
				width: 100%;
                display: flex;
                > div:last-child {
					padding-left: 8px;
                    > div {
                        p {
							width: 100%;
                            font-family: TCloud Number, TCloud Number;
                            font-size: 16px;
                            margin-right: 4px;
                            font-weight: 400;
                        }
						
                    }
					.unit-number{
						span{
							font-size: 12px;
						}
					}
                }
            }
            > div:nth-child(2) {
                margin: 8px 0;
            }
        }
    }
</style>